import React, {useState} from 'react';
import axios from 'axios';
import './tampaThree.css';



const TampaThree = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  //const [state, setState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  
  //switch presentation buttons
  const handleClickT3Pause = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'pause'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3Resume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'resume'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3Next = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'next'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3Previous = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'previous'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3Blackout = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'hide'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3LightUp = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'show'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3Logo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'logo'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3TestScreens = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'test'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3Calendar = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'default'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3FilmingOne = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'Filming one'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3FilmingTwo = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'Filming two'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3FilmingThree = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'Filming three'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3FilmingFour = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'Filming four'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickT3FilmingFive = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/digitals/t3command', {
        message: 'Filming five'
      });

      console.log('data is: ', JSON.stringify(data, null, 4));

      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);
  

  return (
    
    <div className='digital__container'>
      <div className='message__container'>
        <div>
      
          {err && <h2>{err}</h2>}
          <h1>T3</h1>
          <button className="digital__btn" onClick={handleClickT3Calendar}>T3 Calendar</button>
          <button className="digital__btn" onClick={handleClickT3Pause}>T3 Pause</button>
          <button className="digital__btn" onClick={handleClickT3Resume}>T3 Resume</button>
          <button className="digital__btn" onClick={handleClickT3Next}>T3 Next</button>
          <button className="digital__btn" onClick={handleClickT3Previous}>T3 Previous</button>
          <button className="digital__btn" onClick={handleClickT3Blackout}>T3 Blackout</button>
          <button className="digital__btn" onClick={handleClickT3LightUp}>T3 Light Up</button>
          <button className="digital__btn" onClick={handleClickT3Logo}>T3 Logo</button>
          <button className="digital__btn" onClick={handleClickT3TestScreens}>T3 Test Screens</button>
          <button className="digital__btn" onClick={handleClickT3FilmingOne}>T3 Filming One</button>
          <button className="digital__btn" onClick={handleClickT3FilmingTwo}>T3 Filming Two</button>
          <button className="digital__btn" onClick={handleClickT3FilmingThree}>T3 Filming Three</button>
          <button className="digital__btn" onClick={handleClickT3FilmingFour}>T3 Filming Four</button>
          <button className="digital__btn" onClick={handleClickT3FilmingFive}>T3 Filming Five</button>

          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
    </div>
    
  );
};

export default TampaThree;