import './App.css';
import {Routes, Route} from 'react-router-dom'
import Layout from './components/Layout'
import DashLayout from './components/Dashboard/DashLayout'
//import Welcome from './features/auth/Welcome'
import Welcome from './components/Welcome/Welcome'
import UsersList from './features/users/UsersList'
import EditUser from './features/users/EditUser'
import NewUserForm from './features/users/NewUserForm'
import Prefetch from './features/auth/Prefetch'
//import Login from './features/auth/Login'
import PersistLogin from './features/auth/PersistLogin'
import RequireAuth from './features/auth/RequireAuth'
import { ROLES } from './config/roles'

//import {Routes, Route, Navigate} from 'react-router-dom';
import {MiamiControl, TampaControl, Control, SeattleControl, Home} from './components';// add signup when signing up

//import {MiamiControl, TampaControl, Control, Home} from './components'// add signup when signing up
import Login from './components/login/Login'
//import Navbar from './components/navbar/Navbar';
//--------------------
//import PrivateRoute from './components/privateRoute';
//<Route path='/signup' element={<Signup />}/> //this is the sign up route -> only the admin route will get here once user roles are set

const App = () => {
  //development code as of 7-27-2023 @12:24pm

  return(
    <Routes>
      <Route path="/" element={<Layout />}>
        {/* Public Routes */}
        <Route index element={<Home/>} />
        <Route path="login" element={<Login/>}/>

        {/* Protected Routes */}
        <Route element={<PersistLogin/>}>
          <Route element={<RequireAuth allowedRoles={[...Object.values(ROLES)]}/>}>
            <Route element={<Prefetch/>}>
              <Route path="dash" element={<DashLayout/>}>
                <Route index element={<Welcome/>} />

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Miami]}/>}>
                  <Route path="miami">
                    <Route index element={<MiamiControl/>}/>
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Tampa]}/>}>
                  <Route path="tampa">
                    <Route index element={<TampaControl/>}/>
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.FTL]}/>}>
                  <Route path="ftl">
                    <Route index element={<Control/>}/>
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.Admin, ROLES.SuperAdmin, ROLES.Seattle]}/>}>
                  <Route path="seattle">
                    <Route index element={<SeattleControl/>}/>
                  </Route>
                </Route>

                <Route element={<RequireAuth allowedRoles={[ROLES.SuperAdmin]}/>}>
                  <Route path="users">
                    <Route index element={<UsersList/>}/>
                    <Route path=":id" element={<EditUser/>} />
                    <Route path="new" element={<NewUserForm/>} />
                  </Route>

                </Route>
              </Route>
            </Route>
          </Route>
        </Route>
      </Route>
    </Routes>
  )


  //Production code as of 7-27-2023 @12:23pm
  /*
  const user = localStorage.getItem('token')
  return (
    <div className='App'>
      {user && <Navbar/>}
      <Routes>
        {user && <Route path='/' element={<Home />}/>}
        {user && <Route path='/watertaxi' element={<Control />}/>}
        {user && <Route path='/miami' element={<MiamiControl />}/>}
        {user && <Route path='/tampa' element={<TampaControl />}/>}
        {user && <Route path='/seattle' element={<SeattleControl />}/>}
        <Route path='/login' element={<Login />}/>
        <Route path='/' element={<Navigate replace to='/login'/>}/>
        <Route path='/watertaxi' element={<Navigate replace to='/login'/>}/>
        <Route path='/miami' element={<Navigate replace to='/login'/>}/>
        <Route path='/tampa' element={<Navigate replace to='/login'/>}/>
        <Route path='/seattle' element={<Navigate replace to='/login'/>}/>
      </Routes>
    </div>
  );*/
}

export default App;
