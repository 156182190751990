import { Link } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import './Welcome.css';

const Welcome = () => {

    const { username, isMiami, isTampa, isFtl, isSeattle, isAdmin, isSuperAdmin } = useAuth()

    const date = new Date()

    const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'full',
    timeStyle: 'long'}).format(date)
    
    const content = (
        <section className="welcome ">
            

            <h1>Welcome {username}</h1>
            <p>{today}</p>

            {(isMiami || isAdmin || isSuperAdmin ) && <p className="menuItem"><Link to="/dash/miami">Miami Control</Link></p>}

            {(isTampa || isAdmin || isSuperAdmin) && <p className="menuItem"><Link to="/dash/tampa">Tampa Control</Link></p>}

            {(isFtl || isAdmin || isSuperAdmin) && <p className="menuItem"><Link to="/dash/ftl">FTL Taxi Fins Control</Link></p>}

            {(isSeattle || isAdmin || isSuperAdmin) && <p className="menuItem"><Link to="/dash/seattle">Seattle Control</Link></p>}

            {(isSuperAdmin) && <p className="menuItem"><Link to="/dash/users">User Settings</Link></p>}

            {(isSuperAdmin) && <p className="menuItem"><Link to="/dash/users/new">Add New User</Link></p>}

        </section>
    )

    return content
}
 
export default Welcome;