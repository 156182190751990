import React, {useState} from 'react';
import axios from 'axios';
//import BrightnessDropdown from '../../brightnessDropdown/BrightnessDropdown'
import './riverwalk.css';
import '../../brightnessDropdown/brightnessDropdown.css'

const Riverwalk = () => {
  const [data, setData] = useState({data: []});
  const [err, setErr] = useState('');
  const [state, setState] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  /*useEffect(() => {
    setState(JSON.parse(window.localStorage.getItem('state')))
  }, []);

  useEffect(() => {
    window.localStorage.setItem('state', state);
  }, [state]);*/
 

  //geofence
  const handleClickOn = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/trigger/Riverwalk', {
        ammo: 'on'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  const handleClickOff = async () => {
    setState(!state);
    try {
      const {data} = await axios.post('/trigger/Riverwalk', {
        ammo: 'off'
      });

      setData(data);
    } catch (err) {
      setErr(err.message);
      
    } 
  };
  //switch presentation buttons -> need to add all the functions for the message
  const handleClickDefault = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'default'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickPause = async () => {
    setIsLoading(true);
    try {//   /post/one
      const {data} = await axios.post('/post/one', {
        message: 'pause'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickResume = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'resume'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickHide = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'hide'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickShow = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'show'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickNext = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'next'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming1 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming1'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming2 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming2'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming3 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming3'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };
  const handleClickFilming4 = async () => {
    setIsLoading(true);
    try {
      const {data} = await axios.post('/post/one', {
        message: 'filming4'
      });
      console.log('data is: ', JSON.stringify(data, null, 4));
      setData(data);
    } catch (err) {
      setErr(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(data);

  return (
    <div className='riverwalk__container'>
      <div className='message__container'>
        <div>
          {err && <h2>{err}</h2>}
          {state
          ? <h3>Geofence On</h3>
          : <h3>Geofence Off</h3>}
          <h1>Riverwalk</h1>
          {state
          ?<button className="riverwalk__btnClassOff"  onClick={handleClickOff}>Geofence On</button>
          :<button className="riverwalk__btnClassOn" onClick={handleClickOn}>Geofence Off</button>}

          <button className="river_btn" onClick={handleClickDefault}>Riverwalk Calendar</button>
          <button className="river_btn" onClick={handleClickHide}>Riverwalk Blackout</button>
          <button className="river_btn" onClick={handleClickShow}>RiverWalk Show</button>
          <button className="river_btn" onClick={handleClickPause}>Riverwalk Pause</button>
          <button className="river_btn" onClick={handleClickResume}>RiverWalk Resume</button>
          <button className="river_btn" onClick={handleClickNext}>RiverWalk Next</button>
          <button className="river_btn" onClick={handleClickFilming}>RiverWalk loop 1</button>
          <button className="river_btn" onClick={handleClickFilming1}>RiverWalk loop 2</button>
          <button className="river_btn" onClick={handleClickFilming2}>RiverWalk loop 3</button>
          <button className="river_btn" onClick={handleClickFilming3}>RiverWalk loop 4</button>
          <button className="river_btn" onClick={handleClickFilming4}>RiverWalk loop 5</button>
          {isLoading && <h2>Loading...</h2>}

        </div>
      </div>
      {/*<div className='brightness__dropdown'>
        <div>
          <BrightnessDropdown/>
        </div>
        </div>*/}
    </div>
    
  );
};

export default Riverwalk;