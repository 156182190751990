import React from 'react';
import './controlcenter.css'
import Seattle from '../seattle/seattle.jsx';

const SeatleDigitals = () => {
    return(
        <div className='controller'>
            <Seattle />
        </div>
    );
}
 
export default SeatleDigitals;