import { useState, useEffect } from "react"
import { useAddNewUserMutation } from "./usersApiSlice"
import { useNavigate } from "react-router-dom"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSave } from "@fortawesome/free-solid-svg-icons"
import { ROLES } from "../../config/roles"
import { MIAMI_VESSELS, TAMPA_VESSELS, FTL_VESSELS, SEATTLE_VESSELS } from "../../config/vessels"
//import './NewUserForm.css'
import './form.css'
import logo from '../../assets/logoblack.svg'
import { vesselOptions } from "../vessels/vessels"

//const USER_REGEX = /^[A-z]{3,20}$/
const USER_REGEX = /^[A-Za-z0-9]{3,20}$/
const PWD_REGEX = /^[A-z0-9!@#$%]{4,12}$/

const NewUserForm = () => {

    const [addNewUser, {
        isLoading,
        isSuccess,
        isError,
        error
    }] = useAddNewUserMutation()

    const navigate = useNavigate()

    const [username, setUsername] = useState('')
    const [validUsername, setValidUsername] = useState(false)
    const [password, setPassword] = useState('')
    const [validPassword, setValidPassword] = useState(false)
    const [roles, setRoles] = useState(["Miami"])
    const [vessels, setVessels] = useState([])

    useEffect(() => {
        setValidUsername(USER_REGEX.test(username))
    }, [username])

    useEffect(() => {
        setValidPassword(PWD_REGEX.test(password))
    }, [password])

    useEffect(() => {
        if (isSuccess) {
            setUsername('')
            setPassword('')
            setRoles([])
            navigate('/dash/users')
        }
    }, [isSuccess, navigate])

    const onUsernameChanged = e => setUsername(e.target.value)
    const onPasswordChanged = e => setPassword(e.target.value)

    const onRolesChanged = e => {
        const values = Array.from(
            e.target.selectedOptions, //HTMLCollection 
            (option) => option.value
        )

        setVessels([]);
        let MiamiVessels = Object.values(MIAMI_VESSELS);
        let TampaVessels = Object.values(TAMPA_VESSELS);
        let FtlVessels = Object.values(FTL_VESSELS);
        let SeattleVessels = Object.values(SEATTLE_VESSELS);
        values.forEach(function(role) {
            if(role === "Miami")
                setVessels([...vessels, ...MiamiVessels]); 
            else if(role === "Tampa")
                setVessels([...vessels, ...TampaVessels]);
            else if(role === "Ftl")
                setVessels([...vessels, ...FtlVessels]); 
            else if(role === "SEATTLE")
                setVessels([...vessels, ...SeattleVessels]); 
        });

        setRoles(values)
        vesselOptions(roles);
    }

    const onVesselChanged = e => {
        const values = Array.from(
            e.target.selectedOptions, //HTMLCollection 
            (option) => option.value
        )
        setVessels(values)
        vesselOptions(roles);
    }

    const canSave = [roles.length, vessels.length, validUsername, validPassword].every(Boolean) && !isLoading

    const onSaveUserClicked = async (e) => {
        e.preventDefault()
        if (canSave) {
            await addNewUser({ username, password, roles, vessels: [...vessels]});
        }
    }

    const roleOptions = Object.values(ROLES).map(role => {
        return (
            <option
                key={role}
                value={role}

            > {role}</option >
        )
    })

    const errClass = isError ? "errmsg" : "offscreen"
    const validUserClass = !validUsername ? 'form__input--incomplete' : ''
    const validPwdClass = !validPassword ? 'form__input--incomplete' : ''
    const validRolesClass = !Boolean(roles.length) ? 'form__input--incomplete' : ''
    const validVesselsClass = !Boolean(vessels.length) ? 'form__input--incomplete' : ''


    const content = (
        <div className="newUserPage">
            <p className={errClass}>{error?.data?.message}</p>

            <form className="form" onSubmit={onSaveUserClicked}>
                <div className="form__title-row">
                    <img src={logo} alt='logo' />
                    <h2>New User</h2>
                </div>
                <div className="div_input">
                    <label className="form__label" htmlFor="username">
                        Username: <span className="nowrap">[3-20 chars]</span></label>
                    <input
                        className={`form__input ${validUserClass}`}
                        id="username"
                        name="username"
                        type="text"
                        autoComplete="off"
                        value={username}
                        onChange={onUsernameChanged}
                    />
                </div>
                <div className="div_input">
                    <label className="form__label" htmlFor="password">
                        Password: <span className="nowrap">[4-12 chars incl. !@#$%]</span></label>
                    <input
                        className={`form__input ${validPwdClass}`}
                        id="password"
                        name="password"
                        type="password"
                        value={password}
                        onChange={onPasswordChanged}
                    />
                </div>
                <div className="div_selectBoxes">
                    <div>
                        <label className="form__label" htmlFor="roles">
                            ASSIGNED ROLES:</label>
                        <select
                            id="roles"
                            name="roles"
                            className={`form__select ${validRolesClass}`}
                            multiple={true}
                            size="6"
                            value={roles}
                            onChange={onRolesChanged}
                        >
                            {roleOptions}
                        </select>
                    </div>
                    <div>
                        <label className="form__label" htmlFor="vessels">
                            ASSIGNED VESSELS:</label>
                        <select
                            id="vessels"
                            name="vessels"
                            className={`form__select ${validVesselsClass}`}
                            multiple={true}
                            size="4"
                            value={vessels}
                            onChange={onVesselChanged}
                        >
                            {vesselOptions(roles)}
                        </select>
                    </div>
                </div>
                <div className="form__action-buttons">
                        <button
                            className="icon-button"
                            title="Save"
                            disabled={!canSave}
                        >
                            <FontAwesomeIcon icon={faSave} />
                        </button>
                    </div>

            </form>
        </div>
    )

    return content
}
export default NewUserForm
