import React from 'react';
import './controlcenter.css'
import { BallyhooOne, BallyhooTwo, BallyhooThree } from '../digitalBoats';
import { MIAMI_VESSELS } from '../../config/vessels';
import useAuth from "../../hooks/useAuth";

const MiamiDigitals = () => {

    
    const { vessels: allowedVessels, isSuperAdmin } = useAuth();
    const isAllowed = (vessel) => {
        return allowedVessels.includes(vessel);
    }

    let b1Allowed = isAllowed(MIAMI_VESSELS.B1);
    let b2Allowed = isAllowed(MIAMI_VESSELS.B2);
    let b3Allowed = isAllowed(MIAMI_VESSELS.B3); 

    return(
        
        <div className='controller'>
            {(!b1Allowed && !b2Allowed && !b3Allowed&& !isSuperAdmin) && <h1>No Controller Permissions</h1>}

            {(isSuperAdmin || b1Allowed) && <BallyhooOne />}

            {(isSuperAdmin ||b2Allowed) && <BallyhooTwo />}

            {(isSuperAdmin ||b3Allowed) && <BallyhooThree />}
        </div>
    );
}

export default MiamiDigitals;
