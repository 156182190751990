import { MIAMI_VESSELS, TAMPA_VESSELS, FTL_VESSELS, SEATTLE_VESSELS } from "../../config/vessels"

export const vesselOptions = (roles) => {
    let options = [];
    if(roles.includes("Miami") || roles.includes("Admin") || roles.includes("Super")) {
            options = [ 
                ...options, 
                Object.values(MIAMI_VESSELS).map(vessel => {
                    return (
                        <option
                            key={vessel}
                            value={vessel}

                        > {vessel}</option >
                    )
                })
        ]
        
    }
    if(roles.includes("Tampa") || roles.includes("Admin") || roles.includes("Super")) {
        options = [ 
            ...options, 
            (
                Object.values(TAMPA_VESSELS).map(vessel => {
                    return (
                        <option
                            key={vessel}
                            value={vessel}

                        > {vessel}</option >
                    )
                })
            )
        ]
    }
    if(roles.includes("Ftl") || roles.includes("Admin") || roles.includes("Super")) {
        options = [ 
            ...options, 
            (
                Object.values(FTL_VESSELS).map(vessel => {
                    return (
                        <option
                            key={vessel}
                            value={vessel}

                        > {vessel}</option >
                    )
                })
            )
        ]
    }
    if(roles.includes("Seattle") || roles.includes("Admin") || roles.includes("Super")) {
        options = [ 
            ...options, 
            (
                Object.values(SEATTLE_VESSELS).map(vessel => {
                    return (
                        <option
                            key={vessel}
                            value={vessel}

                        > {vessel}</option >
                    )
                })
            )
        ]
    }

    return options;   
}