//import {useState} from 'react';
//import { Link, useNavigate } from 'react-router-dom';
//import { Link } from 'react-router-dom';
//import axios from 'axios';
import logincSS from './login.module.css'
import logo from '../../assets/logoblack.svg'

import { useRef, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

import { useDispatch } from 'react-redux'
import { setCredentials } from '../../features/auth/authSlice'
import { useLoginMutation } from '../../features/auth/authApiSlice'
import usePersist from '../../hooks/usePersist'

const Login = () => {

    // const userRef = useRef()
    const errRef = useRef()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [errMsg, setErrMsg] = useState('')
    const [persist, setPersist] = usePersist()

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [login, { isLoading }] = useLoginMutation()

    /*const [data, setData] = useState({
        username: "",
        password: "",
    })*/
/*
    useEffect(() => {
        userRef.current.focus()
    }, [])*/

    useEffect(() => {
        setErrMsg('');
    }, [username, password])

    //const [error, setError] = useState('');
    //const navigate = useNavigate();
    /*
    const handleChange = ({currentTarget: input}) => {
        setData({...data, [input.name]:input.value});
    }*/

    const handleSubmit = async (e) => {
        e.preventDefault()
        try {
            const { accessToken } = await login({ username, password }).unwrap() //to see error in the handler instead of rtk 
            dispatch(setCredentials({ accessToken }))
            setUsername('')
            setPassword('')
            navigate('/dash')
        } catch (err) {
            if (!err.status) {
                setErrMsg('No Server Response');
            } else if (err.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(err.data?.message);
            }
            errRef.current.focus();
        }
    }

    const handleUserInput = (e) => setUsername(e.target.value)
    const handlePwdInput = (e) => setPassword(e.target.value)
    const handleToggle = () => setPersist(prev => !prev)

    const errClass = errMsg ? "errmsg" : "offscreen"

    if (isLoading) return <p>Loading...</p>

    return(
        <div className={logincSS.login_container}>
            <div className={logincSS.login_form_container}>
                <div className={logincSS.left}>
                <p ref={errRef} className={errClass} aria-live="assertive">{errMsg}</p>
                <form className={logincSS.form_container} onSubmit={handleSubmit}>
                    <img src={logo} alt='logo' />
                        <h1>Login</h1>
                        <input
                            type="username"
							placeholder="username"
							name="username"
							onChange={handleUserInput}
							value={username}
							required
							className={logincSS.input}                  
                        />
                        <input
                            type='password'
                            placeholder='Password' 
                            name='password' 
                            onChange={handlePwdInput}
                            value={password}    
                            required
                            className={logincSS.input}                 
                        />
                        {errMsg && <div className={logincSS.errMsg} > {errMsg} </div>}
                        <button type='submit' className={logincSS.green_btn}>
                            Sign in
                        </button>

                        <label htmlFor="persist" className={logincSS.form__persist}>
                            <input
                                type="checkbox"
                                className={logincSS.form__checkbox}
                                id="persist"
                                onChange={handleToggle}
                                checked={persist}
                            />
                            Trust This Device
                        </label>
                    </form>
                    
                </div>
                
            </div>
        </div>
    )
};

export default Login;
