export const MIAMI_VESSELS = {
    B1: 'b1',
    B2: 'b2',
    B3: 'b3'
}

export const TAMPA_VESSELS = {
    T2: 't2',
    T3: 't3'
}

export const FTL_VESSELS = {
    Riverwalk: 'riverwalk',
    DaleR: 'daleR',
    Lauderdale: 'lauderdale',
    Riovista: 'riovista',
    Bayview: 'bayview'
}

export const SEATTLE_VESSELS = {
    Seattle: "seattle"
}